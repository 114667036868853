import React from "react";

const SectionTwo = () => {
  return (
    <div>
      <div className="secTwo d-flex align-items-center">
        <div className="container tsts d-flex justify-content-center ">
          <div className="tst ">
            <div className="tstOne ">
              <img
                className="tst-icon"
                src="/images/banner-img.png"
                alt="test-icon"
                width={200}
              ></img>
              <h2 className="tst-title">Test Name</h2>
            </div>
          </div>
          <div className="tst  ">
            <div className="tstOne ">
              <img
                className="tst-icon"
                src="/images/banner-img.png"
                alt="test-icon"
                width={200}
              ></img>
              <h2 className="tst-title">Test Name</h2>
            </div>
          </div>
          <div className="tst  ">
            <div className="tstOne ">
              <img
                className="tst-icon"
                src="/images/banner-img.png"
                alt="test-icon"
                width={200}
              ></img>
              <h2 className="tst-title">Test Name</h2>
            </div>
          </div>
          <div className="tst  ">
            <div className="tstOne ">
              <img
                className="tst-icon"
                src="/images/banner-img.png"
                alt="test-icon"
                width={200}
              ></img>
              <h2 className="tst-title">Test Name</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
