import React from "react";

const HeroSection = () => {
  return (
    <div className="hero-sec container d-flex p-0 my-5 ">
      <div className="hero-content align-self-center p-2 flex-grow-1 ">
        <h1 className="display-4 fw-bold text-primary ">
          Keeping a tab
          <span className="span">
            on your <br />
            Health can go
          </span>
          along way
        </h1>
        <div className="hero-line-2 d-flex my-4">
          <span className="hero-line-2-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-box2"
              viewBox="0 0 16 16"
            >
              <path d="M2.95.4a1 1 0 0 1 .8-.4h8.5a1 1 0 0 1 .8.4l2.85 3.8a.5.5 0 0 1 .1.3V15a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4.5a.5.5 0 0 1 .1-.3L2.95.4ZM7.5 1H3.75L1.5 4h6V1Zm1 0v3h6l-2.25-3H8.5ZM15 5H1v10h14V5Z" />
            </svg>
          </span>
          <p className="hero-line-2-text">Check Our Health Packages</p>
        </div>
        <div></div>
        <button className="book btn btn-primary px-5 py-2">
          Book an Appointment
        </button>
      </div>
      <div className="hero-img align-self-center">
        <img src="./images/banner-image.png" alt="Hero-img" width={500} />
      </div>
    </div>
  );
};

export default HeroSection;
